
import { defineComponent, reactive, toRefs } from "vue";
import WorkExperience from "./../updateCv/workExperience.vue";
import { Delete, CirclePlus, EditPen } from "@element-plus/icons-vue";
import { recruitService } from "@/api";
import { ElMessage } from "element-plus/lib/components";
import utils from "@/utils/util";
export default defineComponent({
  props: {
    detail: Object,
  },
  components: {
    WorkExperience,
  },
  setup(props) {
    const state = reactive<{
      autoSize: any;
      detail: any;
      diaLogTitle: string;
      dialogVisible: boolean;
      workExpList: any;
    }>({
      autoSize: { minRows: 4, maxRows: 6 },
      detail: props.detail,
      dialogVisible: false,
      diaLogTitle: "编辑工作经历",
      workExpList: [],
    });
    function edit(name: string, obj?: any) {
      if (obj) {
        const daterange = [
          utils.dateFm(obj.startDate, "YYYY-MM-DD"),
          utils.dateFm(obj.endDate, "YYYY-MM-DD"),
        ];
        obj["guid"] = utils.guid();
        const target = JSON.parse(
          JSON.stringify(Object.assign(obj, { daterange: daterange }))
        );
        state.workExpList = [target];
      } else {
        state.workExpList = [];
      }
      state.diaLogTitle = name;
      state.dialogVisible = true;
    }
    function closeDialog() {
      state.dialogVisible = false;
    }
    function save(body: any) {
      state.detail = Object.assign(state.detail, body);
      closeDialog();
    }
    function deleteInfo(obj: any, index: number) {
      recruitService.user
        .deleteWork(obj)
        .then(() => {
          ElMessage.success("删除成功！");
          state.detail.workExpList.splice(index, 1);
        })
        .catch((val) => {
          if (val.isHttpStatus) {
            console.log(val.statusText);
          } else {
            ElMessage.error(val.message || "错误");
          }
        });
    }
    return {
      ...toRefs(state),
      CirclePlus,
      Delete,
      EditPen,
      edit,
      deleteInfo,
      save,
      closeDialog,
    };
  },
});
