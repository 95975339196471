
import { defineComponent, reactive, toRefs, ref, onMounted } from "vue";
import BasicInfo from "./detailInfoCv/basicInfo.vue";
import Expect from "./detailInfoCv/expect.vue";
import WorkExperience from "./detailInfoCv/workExperience.vue";
import ProjectExperience from "./detailInfoCv/projectExperience.vue";
import EduExperience from "./detailInfoCv/eduExperience.vue";
import {
  Iphone,
  Delete,
  Message,
  EditPen,
  Search,
} from "@element-plus/icons-vue";
import { useRouter } from "vue-router";
import { uploadAction } from "@/api/common";
import { recruitService } from "@/api";
import ViewPdf from "@/components/pc/viewPdf.vue";
import { ElMessage } from "element-plus/lib/components";
export default defineComponent({
  props: {},
  components: {
    BasicInfo,
    Expect,
    WorkExperience,
    ProjectExperience,
    EduExperience,
    Iphone,
    Message,
    Delete,
    Search,
    ViewPdf,
  },
  setup() {
    const router = useRouter();
    const basicInfo = ref<any>();
    const expect = ref<any>();
    const resumeRef = ref<any>();
    const workExperience = ref<any>();
    const projectExperience = ref<any>();
    const eduExperience = ref<any>();
    const state = reactive<{
      activeName: any;
      action: any;
      fileList: any;
      activeIndex: any;
      detail: any;
      originalResumeUrl: any;
      isLoading: boolean;
      isInit: boolean;
      fileType: any;
      dialogVisible: boolean;
      fileUploadTime: any;
    }>({
      isLoading: false,
      isInit: true,
      activeName: "info",
      action: uploadAction,
      fileList: [],
      fileUploadTime: null,
      activeIndex: 0,
      detail: {
        accountName: "",
        sex: null,
        workYears: "",
        lastEduCertText: "",
        age: null,
        mobile: "",
        email: "",
        headUrl:
          "https://image.offersaas.com/offercloud/2022/3/14/952879142695600128/defaultAvatar.png",
        resumeUrl: null,
      },
      originalResumeUrl: null,
      fileType: null,
      dialogVisible: false,
    });
    onMounted(() => {
      state.isLoading = true;
      state.isInit = true;
      recruitService.user
        .userDetail()
        .then((val) => {
          if (val.data) {
            state.detail = val.data;
            if (!state.detail.headUrl) {
              state.detail.headUrl =
                "https://image.offersaas.com/offercloud/2022/3/14/952879142695600128/defaultAvatar.png";
            }
            if (state.detail.currentDistrictCode) {
              state.detail.currentCode = `${state.detail.currentProvCode},${state.detail.currentCityCode},${state.detail.currentDistrictCode}`;
              state.detail.currentName = `${state.detail.currentProvName}/${state.detail.currentCityName}/${state.detail.currentDistrictName}`;
            } else if (state.detail.currentCityCode) {
              state.detail.currentCode = `${state.detail.currentProvCode},${state.detail.currentCityCode}`;
              state.detail.currentName = `${state.detail.currentProvName}/${state.detail.currentCityName}`;
            }
            if (state.detail.hukouDistrictCode) {
              state.detail.hukouCode = `${state.detail.hukouProvCode},${state.detail.hukouCityCode},${state.detail.hukouDistrictCode}`;
              state.detail.hukouName = `${state.detail.hukouProvName}/${state.detail.hukouCityName}/${state.detail.hukouDistrictName}`;
            } else if (state.detail.hukouCityCode) {
              state.detail.hukouCode = `${state.detail.hukouProvCode},${state.detail.hukouCityCode}`;
              state.detail.hukouName = `${state.detail.hukouProvName}/${state.detail.hukouCityName}`;
            }
            if (state.detail.resumeUrl) {
              state.originalResumeUrl = state.detail.resumeUrl;
              const resumeUrl = encodeURIComponent(state.detail.resumeUrl);
              state.fileType = state.originalResumeUrl.split(".").pop();
              state.detail.resumeUrl = `https://lg.offersaas.com/pdfjs/web/viewer.html?file=${resumeUrl}`;
              state.fileUploadTime = val.data.resumeUrlTime;
              state.fileList = [
                {
                  name: state.originalResumeUrl.split("/").pop(),
                  url: state.originalResumeUrl,
                },
              ];
            }
          }

          state.isLoading = false;
          state.isInit = false;
        })
        .catch((val) => {
          state.isLoading = false;
          state.isInit = false;
          if (val.isHttpStatus) {
            console.log(val.statusText);
          } else {
            ElMessage.error(val.message || "错误");
          }
        });
    });
    function editCv() {
      router.push(`/editCv`).catch((err) => {
        console.warn(err);
      });
    }
    function handleExceed() {
      ElMessage.error("只能上传一份简历！");
    }
    function handleSuccess(response: any, file: any, fileList: any[]) {
      if (response.code === 0) {
        file.name = file.name.replace(/,/g, "_");
        file.url = response.data;
        updateResumeUrl(response.data);
      } else {
        const index = fileList.findIndex((d) => d.uid === file.uid);
        if (~index) {
          fileList.splice(index, 1);
        }
        ElMessage.error(response.message || "错误");
      }

      state.fileList = fileList;
    }
    function handleRemove() {
      updateResumeUrl();
    }
    function updateResumeUrl(url?: string) {
      const obj = {
        resumeUrl: url ?? "",
      };
      recruitService.user
        .updateResumeUrl(obj)
        .then((val) => {
          if (url && val.data) {
            state.originalResumeUrl = val.data.resumeUrl;
            const resumeUrl = encodeURIComponent(val.data.resumeUrl);
            state.detail.resumeUrl = `https://lg.offersaas.com/pdfjs/web/viewer.html?file=${resumeUrl}`;
            state.fileType = "pdf";
            state.fileUploadTime = val.data.resumeUrlTime;
            ElMessage.success("上传成功!");
          } else {
            state.originalResumeUrl = null;
            state.detail.resumeUrl = null;
            state.fileUploadTime = null;
            state.activeName = "info";
            state.fileType = "";
            // ElMessage.success("删除成功!");
          }
        })
        .catch((val) => {
          if (val.isHttpStatus) {
            console.log(val.statusText);
          } else {
            ElMessage.error(val.message || "错误");
          }
        });
    }
    function save() {
      console.log(basicInfo.value.getFormData());
    }
    function changeHash(name: string, index: number) {
      state.activeIndex = index;
      const el: any = document.querySelector(`#${name}`);
      el.scrollIntoView(true);
      // document.querySelector(`#${name}`).scrollIntoView(true);
    }
    function handleClick(tab: string, event: Event) {
      console.log(tab, event);
    }
    function toPdf() {
      state.dialogVisible = true;
    }
    function handleClose(done: () => void) {
      state.dialogVisible = false;
      done();
    }
    return {
      ...toRefs(state),
      save,
      changeHash,
      handleClick,
      basicInfo,
      expect,
      workExperience,
      projectExperience,
      eduExperience,
      EditPen,
      editCv,
      resumeRef,
      handleSuccess,
      handleExceed,
      handleRemove,
      toPdf,
      handleClose,
      getFileName(file: string) {
        if (file) {
          return file.split("/").pop();
        } else {
          return "";
        }
      },
      openFile(file: string) {
        window.open(file);
      },
    };
  },
});
