
import { defineComponent, reactive, toRefs } from "vue";
import BasicInfo from "./../updateCv/basicInfo.vue";
import { EditPen } from "@element-plus/icons-vue";
import utils from "@/utils/util";
export default defineComponent({
  props: {
    detail: Object,
  },
  components: {
    BasicInfo,
  },
  setup(props) {
    const state = reactive<{
      autoSize: any;
      detail: any;
      dialogVisible: boolean;
      basicInfoForm: any;
    }>({
      autoSize: { minRows: 4, maxRows: 6 },
      detail: props.detail,
      dialogVisible: false,
      basicInfoForm: null,
    });
    function edit() {
      state.basicInfoForm = {
        accountId: state.detail.accountId,
        accountName: state.detail.accountName,
        jobStatus: state.detail.jobStatus,
        sex: state.detail.sex,
        idCard: state.detail.idCard,
        birth: state.detail.birth,
        birthTime: state.detail.birth
          ? utils.dateFm(state.detail.birth, "YYYY-MM-DD")
          : "",
        age: state.detail.age,
        mobile: state.detail.mobile,
        email: state.detail.email,
        hukouCode: "",
        currentCode: "",
        startWorkDate: state.detail.startWorkTime
          ? utils.dateFm(state.detail.startWorkTime, "YYYY-MM-DD")
          : "",
        startWorkTime: state.detail.startWorkTime,
        lastWorkUnit: state.detail.lastWorkUnit,
        weChat: state.detail.weChat,
        selfEvaluate: state.detail.selfEvaluate,
        headUrl: state.detail.headUrl
          ? state.detail.headUrl
          : "https://image.offersaas.com/offercloud/2022/3/14/952879142695600128/defaultAvatar.png",
      };
      if (state.detail.currentDistrictCode) {
        state.basicInfoForm.currentCode =
          `${state.detail.currentProvCode},${state.detail.currentCityCode},${state.detail.currentDistrictCode}`.split(
            ","
          );
        state.basicInfoForm.currentName = `${state.detail.currentProvName}/${state.detail.currentCityName}/${state.detail.currentDistrictName}`;
      } else if (state.detail.currentCityCode) {
        state.basicInfoForm.currentCode =
          `${state.detail.currentProvCode},${state.detail.currentCityCode}`.split(
            ","
          );
        state.basicInfoForm.currentName = `${state.detail.currentProvName}/${state.detail.currentCityName}`;
      }
      if (state.detail.hukouDistrictCode) {
        state.basicInfoForm.hukouCode =
          `${state.detail.hukouProvCode},${state.detail.hukouCityCode},${state.detail.hukouDistrictCode}`.split(
            ","
          );
        state.basicInfoForm.hukouName = `${state.detail.hukouProvName}/${state.detail.hukouCityName}/${state.detail.hukouDistrictName}`;
      } else if (state.detail.hukouCityCode) {
        state.basicInfoForm.hukouCode =
          `${state.detail.hukouProvCode},${state.detail.hukouCityCode}`.split(
            ","
          );
        state.basicInfoForm.hukouName = `${state.detail.hukouProvName}/${state.detail.hukouCityName}`;
      }
      state.dialogVisible = true;
    }
    function closeDialog() {
      state.dialogVisible = false;
    }
    function save(body: any) {
      state.detail = Object.assign(state.detail, body);
      console.log(body);
      closeDialog();
    }
    return {
      ...toRefs(state),
      edit,
      EditPen,
      closeDialog,
      save,
    };
  },
});
