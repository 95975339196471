
import { EditPen } from "@element-plus/icons-vue";
import Expect from "./../updateCv/expect.vue";
import { defineComponent, reactive, toRefs } from "vue";
export default defineComponent({
  props: {
    detail: Object,
  },
  components: {
    Expect,
  },
  setup(props) {
    const state = reactive<{
      autoSize: any;
      dialogVisible: boolean;
      detail: any;
      expectForm: any;
    }>({
      autoSize: { minRows: 4, maxRows: 6 },
      dialogVisible: false,
      detail: props.detail,
      expectForm: null,
    });
    function edit() {
      if (state.detail) {
        state.expectForm = {
          accountId: state.detail.accountId || "",
          jobType: state.detail.jobType || 1,
          forwardLocationId: state.detail.forwardLocationId || "",
          forwardLocationIdList: state.detail.forwardLocationId
            ? state.detail.forwardLocationId.split(",")
            : "",
          forwardPostName: state.detail.forwardPostName || "",
          forwardPostType: state.detail.forwardPostType || "",
          forwardMinSalary: state.detail.forwardMinSalary || "",
          forwardMaxSalary: state.detail.forwardMaxSalary || "",
        };
      } else {
        state.expectForm = {
          jobType: 1,
          forwardLocationId: "",
          forwardPostName: "",
          forwardPostType: "",
          forwardMinSalary: "",
          forwardMaxSalary: "",
        };
      }
      state.dialogVisible = true;
    }
    function closeDialog() {
      state.dialogVisible = false;
    }
    function save(body: any) {
      state.detail = Object.assign(state.detail, body);
      closeDialog();
    }
    return {
      ...toRefs(state),
      EditPen,
      edit,
      save,
      closeDialog,
    };
  },
});
